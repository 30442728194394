<template>
  <div class="vuse-content-wrapper">
    <!-- Content -->
    <smart-breadcrumbs :items="navigationElements" />
    <v-card elevation="4">
      <div class="filters">
        <v-row class="crud__title-row">
          <help class="help--crud" :helpId="10" />
          <h2 class="text-center">
            Usuarios {{ company.name ? 'de ' + company.name : '' }}
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  dark
                  absolute
                  right
                  style="float: right"
                  @click.prevent="handleCreate()"
                  >Crear
                </v-btn>
              </template>
              <span>Crear Usuario</span>
            </v-tooltip>
          </h2>
        </v-row>
        <label>Filtrar por:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                counter
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="6" style="padding: 1px 2px 1px 2px">
              <multiselect
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                placeholder="País"
                :custom-label="countryName"
                v-model="filters.country"
                :options="countries"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              />
            </v-col>
            <!--<v-col cols="12" lg="1" md="2" sm="6" style="padding: 1px 2px 1px 2px">
										<multiselect
											style="min-width: 80px"
											track-by="id"
											label="name"
											deselect-label=""
											select-label=""
											placeholder="Activa"
											v-model="filters.active"
											:options="activeOptions"
											:multiple="false"
											:close-on-select="true"
											:clear-on-select="false"
											:preserve-search="false"
										></multiselect>
									</v-col>-->
            <v-btn
              color="primary"
              style="min-width: auto; position: absolute; right: 50px"
              dark
              class="px-1 py-1"
              v-if="filterActivated"
              @click.prevent="
                filterActivated = false
                cleanFilters()
                getUsers()
              "
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  style="min-width: auto; position: absolute; right: 10px; height: 25px"
                  dark
                  class="px-1 py-1"
                  @click.prevent="
                    filterActivated = true
                    getUsers()
                  "
                  ><v-icon dark> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>Filtrar</span>
            </v-tooltip>
          </v-row>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="extraButtoms"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
        @showUserRoles="handleRolesRedirect($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'80%'" :width="'90%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Usuario</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Usuario</h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
          {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <div class="row mx-0 mt-4">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Nombre"
                v-model="name"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.max]"
                label="Apellido"
                v-model="lastname"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                :rules="[rules.required, rules.email]"
                label="Email"
                v-model="email"
                type="email"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                v-mask="phoneMask"
                :rules="[rules.required, rules.maxNumber]"
                label="Teléfono"
                v-model="phone"
                type="text"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                counter
                label="Usuario"
                :rules="[rules.required, rules.max]"
                v-model="username"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                label="Contraseña"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.min]"
                v-model="password"
                counter
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                persistent-hint
                hint="Completa este campo si deseas actualizar la contraseña"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                label="Confirmar contraseña"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.min]"
                v-model="confirmPassword"
                counter
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                persistent-hint
                hint="Completa este campo si deseas actualizar la contraseña"
              />
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%">País</label>
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  placeholder="Seleccionar"
                  :custom-label="countryName"
                  v-model="country"
                  :options="countries"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="true"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-row style="margin: 0px">
                <label style="margin: 0px auto; font-size: 13px; width: 20%"
                  >Módulos</label
                >
                <multiselect
                  style="width: 80%"
                  track-by="id"
                  label="name"
                  placeholder="Seleccionar"
                  v-model="selectedModules"
                  :options="modules"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                />
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <div
                class="row"
                style="display: flex; justify-content: center; align-items: center"
              >
                <img
                  v-if="flow === 'edit' && item.avatar"
                  :src="item.avatar.route"
                  :alt="item.name"
                  style="width: 40%"
                />
                <v-file-input
                  v-model="image"
                  chips
                  :rules="[rules.fileSize]"
                  ref="file"
                  show-size
                  accept="image/png, image/jpeg, image/jpg"
                  :label="'Seleccione una foto'"
                  style="width: 60%"
                />
              </div>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-checkbox :label="active ? 'Activo' : 'Inactivo'" v-model="active" />
            </v-col>
          </v-form>
        </div>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
    <modal
      @closed="setDefaultModal()"
      name="rolesModal"
      :height="'80%'"
      :width="'80%'"
      :adaptive="true"
    >
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center">
          Asignación de Roles - {{ item.name + ' ' + item.lastname }}
        </h2>
        <v-stepper elevation="3" v-model="step" vertical>
          <v-stepper-step :complete="step > 1" step="1">
            Seleccione un módulo
          </v-stepper-step>

          <v-stepper-content align="center" step="1">
            <v-row class="mx-0 my-4" style="width: 80%">
              <label style="margin: 0px auto; width: 40%"
                >Módulos asignados: <br /><br /><!--<span
                  v-if="assingedModule !== ''"
                  class="d-flex mb-2"
                  style="font-size: 13px; justify-content: center"
                  ><strong style="display: contents">{{ assingedModule.name }}</strong>
                  <br />
                  Roles asignados: 0 <br />
                  Roles disponibles: 3</span
                >--></label
              >
              <multiselect
                style="width: 60%; min-height: 80px"
                track-by="id"
                label="name"
                placeholder="Seleccionar"
                :custom-label="moduleLabel"
                v-model="assingedModule"
                :options="item.module ? item.module : []"
                @input="getDiffRoles()"
                :multiple="false"
                :allow-empty="false"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="false"
            /></v-row>
            <v-row />

            <v-btn color="primary" :disabled="assingedModule === ''" @click="step = 2">
              Continuar
            </v-btn>
            <v-btn
              text
              @click.prevent="
                $modal.hide('rolesModal')
                modalRoles = false
              "
            >
              Cerrar
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="step > 2" step="2">
            Asigne los roles necesarios para el modulo
            <strong style="display: contents">{{ assingedModule.name }}</strong>
          </v-stepper-step>

          <v-stepper-content align="center" step="2">
            <v-row class="mb-4" justify="center">
              <v-col cols="12" sm="5" class="draggable-container">
                <h3>Roles disponibles</h3>
                <v-divider class="mb-2" />
                <draggable
                  class="list-group"
                  :list="list1"
                  :group="'a'"
                  tag="ul"
                  :sort="false"
                  @change="
                    ''

                  "
                >
                  <v-card
                    shaped
                    outlined
                    class="list-group-item list1 row align-center flex-nowrap no-gutters"
                    v-for="(element, index) in list1"
                    :key="index"
                  >
                    <span class="col-11">
                      {{ element.label }}
                    </span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="col-1" icon x-small v-bind="attrs" v-on="on">
                          <v-icon color="inherit" disabled dense
                            >mdi-information-variant</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ element.description }}</span>
                    </v-tooltip>
                  </v-card>
                </draggable>
              </v-col>
              <v-col cols="1" class="d-flex justify-center align-center">
                <v-icon> mdi-swap-horizontal </v-icon>
              </v-col>
              <v-col cols="12" sm="5" class="draggable-container">
                <h3>Roles asignados</h3>
                <v-divider class="mb-2" />
                <draggable
                  class="list-group"
                  :list="list2"
                  :group="'a'"
                  :sort="false"
                  @change="
                    ''

                  "
                >
                  <v-card
                    shaped
                    class="list-group-item list2 row align-center flex-nowrap no-gutters"
                    v-for="(element, index) in list2"
                    :key="index"
                  >
                    <span class="col-11">
                      {{ element.label }}
                    </span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="col-1" rounded icon small v-bind="attrs" v-on="on">
                          <v-icon small dense>mdi-information-variant</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ element.description }}</span>
                    </v-tooltip>
                  </v-card>
                </draggable>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="assignRoles()"> Guardar </v-btn>
            <v-btn text @click.prevent="step = 1"> Regresar </v-btn>
            <v-btn
              text
              @click.prevent="
                $modal.hide('rolesModal')
                modalRoles = false
              "
            >
              Cancelar
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </modal>
  </div>
</template>

<script>
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import draggable from 'vuedraggable'
  import { decryptData, encryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'

  export default {
    name: 'Users',
    components: {
      SimpleList,
      draggable,
    },
    data() {
      return {
        config: {},
        users: [],
        drag: true,
        loading: true,
        isLoading: false,
        modalRoles: false,
        list1: [],
        list2: [],
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Nombre', value: 'fullName' },
          { text: 'Correo', value: 'email' },
          { text: 'Teléfono', value: 'phone' },
          { text: 'Activo', value: 'activeText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '140px' },
        ],
        items: [],
        modules: [],
        selectedModules: [],
        page: 1,
        pages: 1,
        step: 1,
        item: {},
        company: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        showPassword: false,
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        loadingText: 'Cargando registros',
        filters: {
          search: '',
          active: null,
          country: null,
        },
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activas' },
          { id: 0, name: 'Inactivas' },
        ],
        phoneMask: [
          '+',
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
        ],
        active: false,
        phone: '',
        username: '',
        password: '',
        confirmPassword: '',
        name: '',
        lastname: '',
        email: '',
        countries: [],
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        country: '',
        assingedModule: '',
        selectCountryData: [],
        image: null,
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 6 : true) || '6 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
        extraButtoms: [
          {
            text: 'Asignación de Roles',
            icon: 'mdi-account-key',
            event: { active: true, name: 'showUserRoles' },
          },
        ],
        navigationElements: [
          {
            text: 'Estructura Corporativa',
            disabled: false,
            query: null,
            params: null,
            name: 'business/corporate-structure/AdminPanel',
            href: '/business/corporate-structure/admin-panel',
          },
          {
            text: 'Empresas',
            disabled: false,
            query: null,
            params: null,
            name: 'business/corporate-structure/Companies',
            href: '/business/corporate-structure/companies',
          },
          {
            text: 'Usuarios',
            disabled: true,
            href: '',
          },
        ],
      }
    },
    watch: {},
    created() {
      this.getCompany()
      this.getUsers()
      this.getCountries()
      this.getModules()
    },
    computed: {
      roles() {
        return decryptData(this.$session.get('roles')).map((x) => {
          return x.name
        })
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      async getCompany() {
        this.$axios.get('companies/' + this.$route.params.companyId).then((response) => {
          this.company = response.data
        })
      },

      cleanFilters() {
        this.filters = {
          search: '',
          active: null,
          country: null,
        }
      },

      async getUsers() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        let companyString = ''
        let searchString = ''
        let countryString = ''
        let roleString = ''
        let activeString = ''
        const withString = '&with[]=countries&with[]=module'
        if (this.$route.params.companyId) {
          companyString = '&company_id=' + this.$route.params.companyId
        }
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.countryId && this.filters.countryId.id) {
            countryString = '&country_id=' + this.filters.countryId.id
          }
          if (this.filters.role && this.filters.role.name) {
            roleString = '&role=' + this.filters.role.name
          }
          if (this.filters.active && this.filters.active.id) {
            activeString = '&active=' + this.filters.active.id
          }
        }
        queryParams =
          searchString + countryString + roleString + activeString + withString
        this.$axios
          .get('users?page=' + this.page + companyString + orderString + queryParams)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.forEach((user) => {
              let activeText
              switch (user.active) {
                case true:
                  activeText = 'Si'
                  break
                case false:
                  activeText = 'No'
                  break
                default:
                  activeText = ''
                  break
              }
              user.fullName = `${user.name ? user.name : ''} ${
                user.lastname ? user.lastname : ''
              }`
              user.activeText = activeText
            })
            if (this.modalRoles) {
              this.item = this.items.find((item) => item.id === this.item.id)
            }
            this.loading = false
          })
      },

      moduleLabel({ name }) {
        return name
      },

      handleRolesRedirect(item) {
        this.item = item
        if (this.item.module && this.item.module.length > 0) {
          this.$modal.show('rolesModal')
          this.modalRoles = true
        } else {
          this.$swal({
            title: '¡Aún no!',
            text:
              'Para asignar roles y permisos, primero debes asignar los modulos disponibles para el usuario seleccionado.',
            icon: 'info',
          })
        }
      },

      fullName({ name, lastname, email }) {
        if (name && name !== 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },

      countryName({ name, iso2 }) {
        if (name && iso2) {
          return `${name} (${iso2})`
        } else if (name) {
          return name
        }
      },

      setDefaultModal() {
        this.assingedModule = ''
        this.step = 1
        this.lis1 = []
        this.list2 = []
      },

      async getModules() {
        this.$axios
          .get('modules?limit=50&company_id=' + this.$route.params.companyId)
          .then((response) => {
            this.modules = this.modules.concat(response.data)
          })
      },

      async getCountries() {
        this.$axios.get('countries?limit=500' + this.country).then((response) => {
          this.countries = this.countries.concat(response.data)
        })
      },

      async getDiffRoles() {
        this.$axios
          .post('diff-roles', {
            user_id: this.item.id,
            module_id: this.assingedModule.id,
          })
          .then((response) => {
            if (typeof response.data.diff === 'object') {
              this.list1 = Object.values(response.data.diff)
            } else {
              this.list1 = response.data.diff
            }
            if (typeof response.data.diff === 'object') {
              this.list2 = Object.values(response.data.intersect)
            } else {
              this.list2 = response.data.intersect
            }
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.lastname = ''
        this.country = ''
        this.email = ''
        this.userId = ''
        this.password = null
        this.confirmPassword = null
        this.phone = ''
        this.username = ''
        this.selectedModules = []
        this.active = false

        this.$modal.show('crudModal')
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getUsers()
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.lastname = this.item.lastname
        this.country =
          this.item.countries && this.item.countries.length > 0
            ? this.item.countries[0]
            : ''
        this.email = this.item.email
        this.phone = this.item.phone
        this.password = null
        this.confirmPassword = null
        this.username = this.item.username
        this.selectedModules = this.item.module ? this.item.module : []
        this.active = this.item.active
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
        this.modalRoles = true
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
            : 'Bearer ',
        }

        const formData = new FormData()
        formData.append('company_id', this.$route.params.companyId)
        formData.append('user_id', this.userId)
        formData.append('name', this.name)
        formData.append('lastname', this.lastname)
        formData.append('type', 0)
        formData.append('email', this.email)
        formData.append('phone', this.phone)
        formData.append('username', this.username)
        if (this.country && this.country.id) {
          formData.append('countries[]', this.country.id)
        }
        formData.append('password', this.password)
        formData.append('password_confirmation', this.confirmPassword)
        formData.append('active', this.active ? 1 : 0)
        this.selectedModules.forEach((module) => {
          formData.append('modules[]', module.id)
        })
        if (this.image && this.image !== '' && this.image !== undefined) {
          formData.append('file', this.image)
        }
        formData.append('fileType', 'avatar')
        formData.append('fileResize[width]', 100)
        formData.append('fileResize[height]', 100)
        this.$axios
          .post('users', formData, this.config)
          .then(() => {
            this.page = 1
            this.getUsers()
            this.$modal.hide('crudModal')
          })
          .catch(() => {})
          .finally(() => {})
      },

      async saveItem() {
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
            : 'Bearer ',
        }

        const formData = new FormData()
        formData.append('company_id', this.$route.params.companyId)
        formData.append('name', this.name)
        formData.append('lastname', this.lastname)
        formData.append('email', this.email)
        formData.append('phone', this.phone)
        formData.append('type', 0)
        formData.append('username', this.username)
        if (this.country && this.country.id) {
          formData.append('countries[]', this.country.id)
        }
        if (this.password) {
          formData.append('password', this.password)
        }
        if (this.confirmPassword) {
          formData.append('password_confirmation', this.confirmPassword)
        }
        formData.append('active', this.active ? 1 : 0)
        this.selectedModules.forEach((module) => {
          formData.append('modules[]', module.id)
        })
        if (this.image && this.image !== '' && this.image !== undefined) {
          formData.append('file', this.image)
        }
        formData.append('fileType', 'avatar')
        if (this.item.avatar && this.item.avatar.id) {
          formData.append('fileId', this.item.avatar.id)
        }
        formData.append('_method', 'patch')
        formData.append('fileResize[width]', 200)
        formData.append('fileResize[height]', 200)

        this.$axios
          .post('users/' + this.item.id, formData, this.config)
          .then((response) => {
            this.page = 1
            this.getUsers()
            this.$modal.hide('crudModal')
            if (
              this.item.id === decryptData(this.$session.get('userId')) &&
              response.data.user.modules
            ) {
              this.$store.commit(
                'updateUserModules',
                encryptData(response.data.user.modules)
              )
            }
          })
      },

      async assignRoles() {
        this.$axios
          .patch('users/' + this.item.id, {
            role: this.list2.map((role) => {
              return role.name
            }),
            rolesModule: this.assingedModule.id,
          })
          .then((response) => {
            if (this.item.id === decryptData(this.$session.get('userId'))) {
              this.$store.commit('updateUserRoles', encryptData(response.data.user.roles))
            }

            this.setDefaultModal()
            this.getUsers()
          })
      },

      async deleteItem(item) {
        this.$axios.delete('users/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getUsers()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getUsers()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';

  .v-application--is-ltr .v-stepper--vertical .v-stepper__content {
    margin: -8px 36px;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .draggable-container {
    display: flex;
    flex-direction: column;

    .list-group {
      min-height: 20px;
      height: 100%;
    }
    .list-group-item {
      cursor: move;
      list-style: none;
      margin: 4px 0;
      padding: 4px;
      border: solid 1px #003a56;

      // &.list1 {
      //   border: solid 1px #003a56;
      // }

      // &.list2 {
      //   color: white;
      //   background-color: #003a56;
      // }
      span {
        font-size: 0.8rem;
      }
      i {
        cursor: pointer;
      }
    }
  }
  .multiselect__content-wrapper {
    height: 100px;
  }
</style>
